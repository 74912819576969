import React from 'react'

type TableCellProps = {
  // scope?: string
  className?: string
  width?: string
  bold?: boolean
  children?: React.ReactNode
}

export const TableCell: React.FC<TableCellProps> = ({
  // scope = 'row',
  className,
  width,
  bold = false,
  children
}) => {
  return (
    <td
      className={className || ''}
      style={{
        minWidth: width ? width : '',
        width: width ? width : ''
      }}
    >
      {bold ? <b> {children}</b> : children}
    </td>
  )
}

TableCell.defaultProps = {
  bold: false
}
