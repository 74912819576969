import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormText, Label } from 'reactstrap'
import clsx from 'clsx'

export interface TextAreaInputProps<T> extends UseControllerProps<T> {
  label: string
  errorText?: string
  disabled?: boolean
  autocomplete?: string
  placeholder?: string
  ref?: React.LegacyRef<HTMLTextAreaElement>
  value?: any
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
  minLength?: number
  maxLength?: number
  height?: any
  className?: string
  classes?: Record<'root' | 'label' | 'textarea' | 'error', any>
}

export const TextAreaInput = <T extends FieldValues>(
  props: TextAreaInputProps<T>
) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const {
    label,
    errorText: errorTextProp,
    disabled,
    autocomplete,
    placeholder,
    minLength,
    maxLength,
    className,
    classes,
    height = '100px'
  } = props

  const { name, ref, value, onChange } = controlledProps
    ? controlledProps
    : props

  const onBlur = props.onBlur || controlledProps?.onBlur

  const errorText = errorTextProp || controlledFieldState?.error?.message || ''

  return (
    <div className={clsx(classes?.root, className)}>
      <div className='g-textarea-container'>
        <Label for={name} className={clsx('g-textarea-label', classes?.label)}>
          {label}
        </Label>
        <textarea
          className={clsx('g-textarea', classes?.textarea)}
          id={name}
          name={name}
          disabled={disabled}
          autoComplete={autocomplete}
          placeholder={placeholder}
          ref={ref}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          minLength={minLength}
          maxLength={maxLength}
          style={{ height }}
        />
      </div>
      {Boolean(errorText) && (
        <FormText
          className={clsx('g-textarea-error', classes?.error)}
          color='danger'
        >
          {errorText}
        </FormText>
      )}
    </div>
  )
}

TextAreaInput.defaultProps = {
  type: 'text',
  disabled: false,
  value: ''
}
